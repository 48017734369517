export const BACKEND_PATH: string =
  process.env.BACKEND_PATH || "http://localhost:8080";
export const FRONTEND_PATH: string =
  process.env.FRONTEND_PATH || "http://localhost:3000";

export const NO_BUILDING_IMAGE_PATH = "/empty/building.png";
export const BLUR_BUILDING_IMAGE_PATH = "/blur_building.png";
export const NO_UNIT_IMAGE_PATH = "/empty/unit.png";
export const NO_USER_IMAGE_PATH = "/empty/user.svg";
export const IS_PRODUCTION = process.env.NODE_ENV === "production";
export const IS_PDF_DEV =
  !IS_PRODUCTION && process.env.NEXT_PUBLIC_IS_PDF_DEV === "1";
