import { useCallback, useState } from "react";
import { z } from "zod";

export type RangeFormInputsType = {
  min: number | undefined;
  setMin: (value: string) => void;
  max: number | undefined;
  setMax: (value: string) => void;
  initialize: () => void;
};

function triggerIfNonNegativeNumber(
  callback: (val: number | undefined) => void,
): (value: string) => void {
  return (value: string) => {
    if (value.length === 0) {
      callback(undefined);
      return;
    }
    const parsed = z
      .preprocess(Number, z.number().nonnegative().optional())
      .safeParse(value);
    if (parsed.success) {
      callback(parsed.data);
    }
  };
}

const useRangeForm = (): RangeFormInputsType => {
  const [min, setMin] = useState<number | undefined>(undefined);
  const [max, setMax] = useState<number | undefined>(undefined);
  const initialize = useCallback(() => {
    setMin(undefined);
    setMax(undefined);
  }, []);

  return {
    min,
    setMin: triggerIfNonNegativeNumber(setMin),
    max,
    setMax: triggerIfNonNegativeNumber(setMax),
    initialize,
  };
};

export default useRangeForm;
