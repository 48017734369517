import { css } from "@emotion/react";
import { text, radius } from "@estie-inc/design-tokens";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NextLink from "next/link";
import React, { ReactNode, useState } from "react";
import { NO_USER_IMAGE_PATH } from "../../../config";
import { DASHBOARD_PAGE } from "../../../contexts/dashboard";
import { transition } from "../../../styles/mixin";
import {
  Color,
  Spacing,
  Shadow,
  RadiusSize,
  ZIndex,
  LineHeight,
} from "../../../tokens";
import { productLogoUrl } from "../../../utils/BrandConstants";
import {
  isAnalyticsEnabled,
  isDevAccount,
  isDashboardEnabled,
  isProposalDraftsEnabled,
  isSalesListEnabled,
  isTenantAgreementAndCancellationEnabled,
} from "../../../utils/SplitFlags";
import MenuList from "../../uiParts/navigation/MenuList";
import Tab from "../../uiParts/navigation/Tab";

type Props = {
  lessorUser: {
    name: string;
    companyName: string;
    imageUrl?: string;
    email: string;
  };
  currentTab?: TabLabel;
};

type TabItem = {
  label: string;
  href?: string;
  menu?: ReactNode;
  isSelected?: boolean;
  testId?: string;
};

type TabLabel =
  | "properties"
  | "askings"
  | "deal-management"
  | "sales-list"
  | "analytics"
  | "applications"
  | "receivers"
  | "dashboard"
  | "proposal-drafts";

const Header: React.FC<Props> = ({ lessorUser, currentTab }) => {
  const [openMenu, toggleMenu] = useState(false);
  const [isEnter, setEnter] = useState(false);
  const tabItems: TabItem[] = [
    {
      label: "物件",
      href: "/lessor/properties",
      isSelected: currentTab === "properties",
    },
    {
      label: "募集",
      href: "/lessor/askings",
      isSelected: currentTab === "askings",
    },
  ];
  isTenantAgreementAndCancellationEnabled(lessorUser) &&
    tabItems.push({
      label: "成約・解約",
      href: "/lessor/deal-management",
      isSelected: currentTab === "deal-management",
    });
  isSalesListEnabled(lessorUser) &&
    tabItems.push({
      label: "営業資料",
      href: "/lessor/sales-list",
      isSelected: currentTab === "sales-list",
    });
  isAnalyticsEnabled(lessorUser) &&
    tabItems.push({
      label: "営業レポート",
      href: "/lessor/analytics",
      isSelected: currentTab === "analytics",
    });
  isProposalDraftsEnabled(lessorUser) &&
    tabItems.push({
      label: "提案リスト",
      href: "/lessor/proposal-drafts",
      isSelected: currentTab === "proposal-drafts",
    });
  isDashboardEnabled(lessorUser) &&
    tabItems.push({
      label: "分析",
      isSelected: currentTab === "dashboard",
      menu: (
        <div css={navMenuStyle}>
          <ul>
            <li>
              <NextLink
                css={navMenuItemStyle}
                href={DASHBOARD_PAGE.BUILDINGS}
                passHref
              >
                自社物件
              </NextLink>
            </li>
            <li>
              <NextLink
                css={navMenuItemStyle}
                href={DASHBOARD_PAGE.STATIONS}
                passHref
              >
                エリア比較
              </NextLink>
            </li>
            <li>
              <NextLink
                css={navMenuItemStyle}
                href={DASHBOARD_PAGE.COMPETITORS}
                passHref
              >
                エリア内競合
              </NextLink>
            </li>
            <li>
              <NextLink
                css={navMenuItemStyle}
                href={DASHBOARD_PAGE.BROKERS}
                passHref
              >
                仲介会社
              </NextLink>
            </li>
          </ul>
        </div>
      ),
    });
  isDevAccount(lessorUser.email) &&
    tabItems.push({
      label: "仲介事業者",
      href: "/lessor/receivers",
      isSelected: currentTab === "receivers",
    });

  return (
    <div css={headerStyle}>
      <div css={navStyle}>
        <NextLink href="/lessor/properties" passHref>
          <img src={productLogoUrl} alt="ロゴ画像" css={navLogoStyle} />
        </NextLink>
        <Tab tabItems={tabItems} />
      </div>
      <button
        css={userStyle(openMenu)}
        onClick={() => toggleMenu(!openMenu)}
        onMouseEnter={() => {
          setEnter(true);
        }}
        onMouseLeave={() => {
          setEnter(false);
        }}
        onBlur={() => {
          if (!isEnter) {
            toggleMenu(false);
          }
        }}
      >
        <img
          src={lessorUser.imageUrl || NO_USER_IMAGE_PATH}
          alt={"プロフィール画像"}
          css={userImgStyle}
        />
        <div css={userNameStyle}>
          <p css={userNameCompanyStyle}>{lessorUser.companyName}</p>
          <p css={userNameAccountStyle}>{lessorUser.name}</p>
        </div>
        <FontAwesomeIcon icon={faCaretDown} css={userOpenIconStyle} />
        {openMenu && (
          <MenuList
            menuItems={[
              { label: "マイページ", href: `/lessor/mypage` },
              { label: "ログアウト", href: "/api/auth/logout" },
            ]}
            css={userMenuStyle}
          />
        )}
      </button>
    </div>
  );
};

const headerStyle = css`
  position: fixed;
  top: 0;
  display: flex;
  z-index: ${ZIndex.Header};
  width: 100%;
  background: ${Color.White};
  justify-content: space-between;
  align-items: center;
  padding: 0 ${Spacing[4]} 0 ${Spacing[12]};
  box-shadow: ${Shadow.Box[1]};
  @media print {
    display: none;
  }
`;

const navStyle = css`
  display: flex;
  gap: ${Spacing[16]};
  align-items: center;
`;

const navLogoStyle = css`
  height: 28px;
  width: auto
  cursor: pointer;
`;

const userStyle = (openMenu?: boolean) => css`
  position: relative;
  display: flex;
  align-items: center;
  min-height: 40px;
  text-align: left;
  padding: ${Spacing[2]} ${Spacing[12]} ${Spacing[2]} ${Spacing[2]};
  border-radius: ${RadiusSize[999]};
  cursor: pointer;
  ${transition(0.2)}
  background: ${openMenu ? Color.Neutral.Light.Secondary : "transparent"};
  &:hover {
    background: ${Color.Neutral.Light.Secondary};
  }
`;

const userImgStyle = css`
  width: 36px;
  height: 36px;
  border: 1px solid ${Color.Opacity[20]};
  border-radius: ${RadiusSize[999]};
  object-fit: cover;
`;

const userNameStyle = css`
  padding-left: ${Spacing[8]};
`;

const userNameCompanyStyle = css`
  font-size: ${text.size[10]};
  line-height: ${LineHeight[100]};
`;

const userNameAccountStyle = css`
  font-size: ${text.size[14]};
  line-height: ${LineHeight[100]};
  margin-top: ${Spacing[4]};
`;

const userOpenIconStyle = css`
  font-size: ${text.size[14]};
  padding-left: ${Spacing[8]};
`;

const userMenuStyle = css`
  position: absolute;
  top: 46px;
  right: 0;
  width: 100%;
  min-width: 120px;
  z-index: ${ZIndex.PageHead};
`;

const navMenuStyle = css`
  min-width: 120px;
  border: 1px solid ${Color.Neutral.Light.Primary};
  border-radius: ${radius[2]};
  background: ${Color.White};
`;

const navMenuItemStyle = css`
  display: block;
  color: ${Color.Neutral.Base.Primary};
  padding: ${Spacing[8]};
  font-size: ${text.size[12]};
  border-top: 1px solid ${Color.Neutral.Light.Primary};
`;

export default Header;
