import { useState } from "react";
import { YearMonth } from "../components/uiParts/dataEntry/MonthRangePicker";

type Props = {
  initialStart: YearMonth;
  initialEnd: YearMonth;
};

export const useMonthRangePicker = (props: Props) => {
  const [currentStart, setCurrentStart] = useState<Partial<YearMonth>>(
    props.initialStart,
  );
  const [currentEnd, setCurrentEnd] = useState<Partial<YearMonth>>(
    props.initialEnd,
  );
  const [displayStart, setDisplayStart] = useState<YearMonth>(
    props.initialStart,
  );
  const [displayEnd, setDisplayEnd] = useState<YearMonth>(props.initialEnd);

  const yearOptions = new Array(10)
    .fill(0)
    .map((_, index) => new Date().getFullYear() - 9 + index);

  const isNonPartial = (
    yearMonth: Partial<YearMonth>,
  ): yearMonth is YearMonth =>
    yearMonth.year !== undefined && yearMonth.month !== undefined;

  const onChange = (start: Partial<YearMonth>, end: Partial<YearMonth>) => {
    setCurrentStart(start);
    setCurrentEnd(end);
  };

  const onResult = (start: Partial<YearMonth>, end: Partial<YearMonth>) => {
    if (isNonPartial(start) && isNonPartial(end)) {
      setDisplayStart(start);
      setDisplayEnd(end);
    }
  };

  const isAllFilled = isNonPartial(currentStart) && isNonPartial(currentEnd);
  const isOrderCorrect =
    isNonPartial(currentStart) &&
    isNonPartial(currentEnd) &&
    (currentStart.year < currentEnd.year ||
      (currentStart.year === currentEnd.year &&
        currentStart.month <= currentEnd.month));

  const [isDisabled, disabledTooltip] = !isAllFilled
    ? [true]
    : !isOrderCorrect
    ? [
        true,
        {
          isDisplayed: true,
          message: "集計終了月は集計開始月より後の年月を入力してください",
        },
      ]
    : [false];

  return {
    currentStart,
    currentEnd,
    displayStart,
    displayEnd,
    yearOptions,
    onChange,
    onResult,
    isDisabled,
    disabledTooltip,
  };
};
