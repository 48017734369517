import { useState, useCallback } from "react";
import useRangeForm, { RangeFormInputsType } from "./useRangeForm";

export type SearchFormStationInputsType = {
  stationId: number | undefined;
  setStationId: (value: number | undefined) => void;
  timeRequired: number | undefined;
  setTimeRequired: (value: number | undefined) => void;
  stationName: string | undefined;
  setStationName: (value: string | undefined) => void;
};

export type SearchFormInputsType = {
  stationForm: SearchFormStationInputsType;
  areaForm: RangeFormInputsType;
  rentForm: RangeFormInputsType;
  completedYearForm: RangeFormInputsType;
  initialize: () => void;
};

const useSearchFormInputs = (): SearchFormInputsType => {
  const [stationId, setStationId] = useState<number | undefined>(undefined);
  const [timeRequired, setTimeRequired] = useState<number | undefined>(5);
  const [stationName, setStationName] = useState<string | undefined>(undefined);
  const areaForm = useRangeForm();
  const rentForm = useRangeForm();
  const completedYearForm = useRangeForm();

  const initialize = useCallback(() => {
    setStationId(undefined);
    setTimeRequired(5);
    setStationName(undefined);
    areaForm.initialize();
    rentForm.initialize();
    completedYearForm.initialize();
  }, [areaForm, completedYearForm, rentForm]);

  return {
    stationForm: {
      stationId,
      setStationId,
      timeRequired,
      setTimeRequired,
      stationName,
      setStationName,
    },
    areaForm,
    rentForm,
    completedYearForm,
    initialize,
  };
};

export default useSearchFormInputs;
