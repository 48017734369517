import { getMonth, getYear, sub as subtractDate } from "date-fns";
import { useRouter } from "next/router";
import { FC, ReactNode, createContext, useContext, useState } from "react";
import useDashboardStation from "../../components/organisms/lessor/dashboard/stations/hooks";
import { Props as MonthRangePickerProps } from "../../components/uiParts/dataEntry/MonthRangePicker";
import { useMonthRangePicker } from "../../hooks/useMonthRangePicker";
import useAnalyticsSearch, { DashboardType } from "./hooks/useAnalyticsSearch";
import useSearchFormInputs, {
  SearchFormInputsType,
} from "./hooks/useSearchFormInputs";

export enum DASHBOARD_PAGE {
  BUILDINGS = "/lessor/dashboard/buildings",
  COMPETITORS = "/lessor/dashboard/competitors",
  BROKERS = "/lessor/dashboard/brokers",
  STATIONS = "/lessor/dashboard/stations",
}

type DashboardContextType = {
  searchForm: SearchFormInputsType;
  monthlyAggPeriod: MonthRangePickerProps;
  searchInputs: SearchFormInputsType | undefined;
  setSearchInputs: (searchInputs: SearchFormInputsType) => void;
  isSubmitting: boolean;
  searchResult: DashboardType | undefined;
  stationSearchProps: ReturnType<typeof useDashboardStation>;
  page: DASHBOARD_PAGE | undefined;
};

const defaultValues: DashboardContextType = {
  searchForm: {} as SearchFormInputsType,
  monthlyAggPeriod: {} as MonthRangePickerProps,
  searchInputs: undefined,
  setSearchInputs: (_: SearchFormInputsType) => {},
  isSubmitting: false,
  searchResult: undefined,
  stationSearchProps: {} as ReturnType<typeof useDashboardStation>,
  page: undefined,
};

const DashboardContext = createContext(defaultValues);

type Props = {
  children: ReactNode;
};

export const useDashboardContext = () => {
  return useContext(DashboardContext);
};

export const DashboardProvider: FC<Props> = ({ children }) => {
  const [searchInputs, setSearchInputs] = useState<
    SearchFormInputsType | undefined
  >(undefined);

  const searchForm = useSearchFormInputs();
  const monthlyAggPeriod = useMonthRangePicker({
    initialStart: {
      year: getYear(subtractDate(new Date(), { months: 6 })),
      month: getMonth(subtractDate(new Date(), { months: 6 })) + 1,
    },
    initialEnd: { year: getYear(new Date()), month: getMonth(new Date()) + 1 },
  });

  const { isSubmitting, searchResult } = useAnalyticsSearch(
    searchInputs,
    monthlyAggPeriod.displayStart,
    monthlyAggPeriod.displayEnd,
  );

  const stationSearchProps = useDashboardStation(monthlyAggPeriod);

  const router = useRouter();
  const page = Object.values(DASHBOARD_PAGE).find((v) => v === router.pathname);

  return (
    <DashboardContext.Provider
      value={{
        ...defaultValues,
        searchForm,
        monthlyAggPeriod,
        searchInputs,
        setSearchInputs,
        page,
        isSubmitting,
        searchResult,
        stationSearchProps,
      }}
    >
      {children}
    </DashboardContext.Provider>
  );
};
