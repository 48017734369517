import { useCallback, useState } from "react";
import { Station } from "../../../../../../types/station";
import { Props as MonthRangePickerProps } from "../../../../../uiParts/dataEntry/MonthRangePicker";
import useStationAnalyticsSearch, {
  StationRankItem,
} from "./useStationAnalyticsSearch";

type DashboardStationType = {
  searchedStations: Station[];
  onSearchStations: (stations: Station[]) => void;
  isSubmitting: boolean;
  stationRankItems: StationRankItem[] | undefined;
};

const useDashboardStation = (
  monthlyAggPeriod: MonthRangePickerProps,
): DashboardStationType => {
  const [searchedStations, setSearchedStations] = useState<Station[]>([]);
  const { isSubmitting, stationRankItems } = useStationAnalyticsSearch(
    searchedStations.map((s) => s.id),
    monthlyAggPeriod.displayStart,
    monthlyAggPeriod.displayEnd,
  );

  const onSearchStations = useCallback(
    (stations: Station[]) => setSearchedStations(stations),
    [],
  );

  return {
    searchedStations,
    onSearchStations,
    isSubmitting,
    stationRankItems,
  };
};

export default useDashboardStation;
