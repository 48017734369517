import { useQuery } from "urql";
import { AnalyticsStationsDocument } from "../../../../../../graphql";
import { Station } from "../../../../../../types/station";
import { YearMonth } from "../../../../../uiParts/dataEntry/MonthRangePicker";

export type StationAnalyticsSearchType = {
  isSubmitting: boolean;
  stationRankItems: StationRankItem[] | undefined;
};

export type StationRankItem = {
  station: Station;
  rank: number;
  hasOwnBuilding: boolean;
  rabbitSearchedCount: number;
};

const useStationAnalyticsSearch = (
  stationIds: number[],
  start: YearMonth,
  end: YearMonth,
): StationAnalyticsSearchType => {
  const [{ data, fetching }] = useQuery({
    query: AnalyticsStationsDocument,
    variables: {
      ids: stationIds,
      period: {
        startYear: start.year,
        startMonth: start.month,
        endYear: end.year,
        endMonth: end.month,
      },
    },
    pause: stationIds.length === 0,
  });

  if (!data) return { isSubmitting: fetching, stationRankItems: undefined };

  // 出てきた検索回数の降順リスト
  const searchedCounts = [
    ...new Set(data.stationsByIds.map((s) => s.rabbitSearchedCount)),
  ].sort((a, b) => b - a);

  const stationRankItems: StationRankItem[] = [];
  searchedCounts.forEach((searchedCount) => {
    const rank = stationRankItems.length + 1;
    stationRankItems.push(
      ...data.stationsByIds
        .filter((s) => s.rabbitSearchedCount === searchedCount)
        .map(({ id, name, rabbitSearchedCount, hasOwnBuilding }) => ({
          station: { id, name },
          rank,
          hasOwnBuilding,
          rabbitSearchedCount,
        })),
    );
  });

  return {
    isSubmitting: fetching,
    stationRankItems,
  };
};

export default useStationAnalyticsSearch;
