import { css } from "@emotion/react";
import { text } from "@estie-inc/design-tokens";
import { IconCaretDown } from "@estie-inc/estie-ui";
import NextLink from "next/link";
import React, { ReactNode } from "react";
import { Color, Spacing, LineHeight } from "../../../../tokens";

type Props = {
  tabItems: TabItem[];
};

type TabItem = {
  label: string;
  onClick?: () => void;
  href?: string;
  menu?: ReactNode;
  isSelected?: boolean;
  testId?: string;
};

const Tab: React.FC<Props> = ({ tabItems }) => {
  return (
    <ul css={listStyle}>
      {tabItems.map((item: TabItem, index) => {
        return (
          <>
            <li
              key={index}
              css={itemStyle}
              onClick={item.onClick}
              data-testid={item.testId}
            >
              {item.href ? (
                <NextLink
                  href={item.href}
                  css={headingStyle(item.isSelected)}
                  data-testid={item.testId}
                >
                  <span>
                    {item.label}
                    {item.menu && (
                      <IconCaretDown variant="filled" css={iconStyle} />
                    )}
                  </span>
                </NextLink>
              ) : (
                <div css={headingStyle(item.isSelected)}>
                  <span>{item.label}</span>
                  {item.menu && (
                    <IconCaretDown variant="filled" css={iconStyle} />
                  )}
                </div>
              )}
              {item.menu && (
                <div className="navigation-tab__menu">{item.menu}</div>
              )}
            </li>
          </>
        );
      })}
    </ul>
  );
};

const listStyle = css`
  display: flex;
  height: 50px;
`;

const itemStyle = css`
  position: relative;
  .navigation-tab__menu {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    padding-top: ${Spacing[4]};
  }
  &:hover {
    .navigation-tab__menu {
      display: block;
    }
  }
`;

const headingStyle = (isSelected?: boolean) => css`
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  color: ${isSelected
    ? Color.Neutral.Base.Primary
    : Color.Neutral.Base.Secondary};
  font-size: ${text.size[14]};
  font-weight: ${isSelected ? "bold" : "normal"};
  line-height: ${LineHeight[140]};
  padding: 0 ${Spacing[12]};
  cursor: pointer;
  &:hover {
    color: ${Color.Neutral.Base.Primary};
    font-weight: bold;
    text-decoration: none;
  }

  &::before {
    content: ${isSelected ? '""' : "none"};
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: ${Color.Neutral.Base.Primary};
  }
`;

const iconStyle = css`
  margin-left: ${Spacing[4]};
`;

export default Tab;
