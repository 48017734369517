import { useQuery } from "urql";
import { YearMonth } from "../../../components/uiParts/dataEntry/MonthRangePicker";
import {
  GraphQLBrokerRanking,
  GraphQLBuildingSummary,
  GraphQLDashboard,
  GraphQLRabbitAsking,
  GraphQLRabbitBuilding,
  LessorSearchDemandDocument,
  LessorSearchDemandQuery,
  ReportBuildingQuery,
} from "../../../graphql";
import { SearchFormInputsType } from "./useSearchFormInputs";

export type RankItemDetail = {
  addedCount: number;
  askings: GraphQLRabbitAsking[];
  askingSummary?: AskingSummary; // MRの権限がない場合は undefined
  buildingId?: number;
  rabbitBuilding: GraphQLRabbitBuilding;
  rank: number;
};

export type AskingSummary = {
  areaTsuboMax?: number;
  areaTsuboMin?: number;
  rentMax?: number;
  rentMin?: number;
};

export type DashboardType = {
  brokerRanking: GraphQLBrokerRanking;
  buildingSummaries: Array<GraphQLBuildingSummary>;
  rankItemDetails: RankItemDetail[];
  totalCount: number;
};

export type SearchBrokersType = {
  isSubmitting: boolean;
  searchResult: DashboardType | undefined;
};

export const toRankItemDetails = (
  lessorSearchDemands:
    | LessorSearchDemandQuery["lessorSearchDemands"]
    | ReportBuildingQuery["lessorSearchDemands"]
    | undefined,
): RankItemDetail[] => {
  if (!lessorSearchDemands) return [];
  const rankItemDetails = lessorSearchDemands.topTwentyRabbitBuildings.map(
    (rabbitBuilding) => {
      const summary = lessorSearchDemands.buildingSummaries.find(
        (summary) => summary.rabbitBuildingId === rabbitBuilding.id,
      )!;
      const askings = summary.askings;
      const areaTsubos = askings.flatMap((asking) =>
        asking.area ? [asking.area] : [],
      );
      const rents = askings.flatMap((asking) =>
        asking.rent ? [asking.rent] : [],
      );
      return {
        addedCount: new Set(askings.map((a) => a.proposalDraftId)).size,
        askingSummary: rabbitBuilding.details
          ? {
              areaTsuboMax: areaTsubos.length
                ? Math.max(...areaTsubos)
                : undefined,
              areaTsuboMin: areaTsubos.length
                ? Math.min(...areaTsubos)
                : undefined,
              rentMax: rents.length ? Math.max(...rents) : undefined,
              rentMin: rents.length ? Math.min(...rents) : undefined,
            }
          : undefined,
        askings,
        buildingId: summary?.building?.id,
        rabbitBuilding,
        rank: summary?.ranking,
      } as RankItemDetail;
    },
  );
  // ランキング順、同着なら閲覧数が多い順にソート
  rankItemDetails.sort((a, b) =>
    a.rank !== b.rank
      ? a.rank - b.rank
      : b.rabbitBuilding.impressionCount - a.rabbitBuilding.impressionCount ||
        0,
  );

  return rankItemDetails;
};

const useAnalyticsSearch = (
  input: SearchFormInputsType | undefined,
  start: YearMonth,
  end: YearMonth,
): SearchBrokersType => {
  const [{ data, fetching }] = useQuery({
    query: LessorSearchDemandDocument,
    variables: {
      command: {
        stationId: input?.stationForm.stationId,
        stationTimeRequired: input?.stationForm.timeRequired || -1, // required なので-1をセット。TODO: エリア検索追加時に修正
        minArea: input?.areaForm.min,
        maxArea: input?.areaForm.max,
        minRent: input?.rentForm.min,
        maxRent: input?.rentForm.max,
        minCompletedYear: input?.completedYearForm.min,
        maxCompletedYear: input?.completedYearForm.max,
        period: {
          startYear: start.year,
          startMonth: start.month,
          endYear: end.year,
          endMonth: end.month,
        },
      },
      period: {
        startYear: start.year,
        startMonth: start.month,
        endYear: end.year,
        endMonth: end.month,
      },
    },
    pause: input === undefined,
  });

  if (!data) return { isSubmitting: fetching, searchResult: undefined };
  const rankItemDetails = toRankItemDetails(data.lessorSearchDemands);

  return {
    isSubmitting: fetching,
    searchResult: {
      ...(data.lessorSearchDemands as GraphQLDashboard),
      rankItemDetails,
    },
  };
};

export default useAnalyticsSearch;
