import { ApiLessorUser } from "../repository";

type LessorUserForSplitFlags = Pick<ApiLessorUser, "email">;

// 開発者用アカウントのみのグループ
// 開発中の機能や、実際のユーザーでは出し分けている機能もすべて見えるようにしている
export const isDevAccount = (email: string) => {
  return email === "dev_lease_lessor@estie.co.jp";
};

// ↓ ユーザーの所属組織による分類

// estie 社員のアカウントのグループ
// OPS チームのデモ用アカウントにも影響する
export const isEstie = (email: string) => {
  return email.endsWith("@estie.co.jp") && !email.startsWith("fl+");
};

// estie 社員の各社検証用アカウント
export const isEstieVerification = (email: string) => {
  return email.endsWith("@estie.co.jp") && email.startsWith("fl+");
};

// 野村不動産のユーザーと検証用アカウント
export const isNomura = (email: string) => {
  return email.endsWith("@nomura-re.co.jp") || email === "fl+15@estie.co.jp";
};

// 日鉄興和不動産のユーザーと検証用アカウント
export const isNittetsu = (email: string) => {
  return email.endsWith("@nskre.co.jp") || email === "fl+19@estie.co.jp";
};

// MHCRのユーザーと検証用アカウント
export const isMHCR = (email: string) => {
  return (
    email.endsWith("@mitsubishi-hc-capital.com") ||
    email === "fl+31@estie.co.jp"
  );
};

// ↓ 機能の出しわけ用の分類

// 商談機能の表示制御に使うフラグ
export const isDealEnabled = (
  lessorUser?: LessorUserForSplitFlags,
): boolean => {
  if (!lessorUser) return false;
  // 野村様と日鉄様以外は利用できる
  return !isNomura(lessorUser.email) && !isNittetsu(lessorUser.email);
};

// 商談に紐づかない成約、解約機能の表示制御に使うフラグ
// 商談利用ユーザーは基本的に使えないようにするが、開発者は両方使えるようにする
export const isTenantAgreementAndCancellationEnabled = (
  lessorUser?: LessorUserForSplitFlags,
): boolean => {
  if (!lessorUser) return false;
  // 野村様、日鉄様（商談機能非利用）と開発者ユーザーは利用できる
  return !isDealEnabled(lessorUser) || isDevAccount(lessorUser.email);
};

// PF-3039
// リーシングログと解約はデータ上全く別個であるため、双方利用しても問題なく、テナント機能への移行前提で解約タブをリーシングログユーザーにも解放する
// isDealEnabled と isTenantAgreementAndCancellationEnabled は同時に true にならないが
// 解約は商談を使うユーザー、成約を使うユーザー双方から使われる
export const isTenantCancellationEnabled = (
  lessorUser?: LessorUserForSplitFlags,
): boolean => {
  if (!lessorUser) return false;

  // 結果的には全ユーザーへの解放と同じ
  return (
    isDealEnabled(lessorUser) ||
    isTenantAgreementAndCancellationEnabled(lessorUser)
  );
};

// 営業レポート（analytics）機能の表示制御に使うフラグ
export const isAnalyticsEnabled = (
  lessorUser?: LessorUserForSplitFlags,
): boolean => {
  if (!lessorUser) return false;
  // MHCR様以外は利用できる
  // MHCR様はセキュリティ上ダウンロード機能をつけられないため、営業レポート機能も利用できないようにする
  return !isMHCR(lessorUser.email);
};

// 営業資料機能の表示制御に使うフラグ
export const isSalesListEnabled = (
  lessorUser?: LessorUserForSplitFlags,
): boolean => {
  if (!lessorUser) return false;
  // MHCR様以外は利用できる
  // MHCR様はセキュリティ上ダウンロード機能をつけられないため、営業資料機能も利用できないようにする
  return !isMHCR(lessorUser.email);
};

// 営業資料の汎用PDFに募集検索の広告表示制御に使うフラグ
export const isBosyuAdEnabled = (
  lessorUser?: LessorUserForSplitFlags,
): boolean => {
  if (!lessorUser) return false;
  // 日鉄ユーザーには表示しない
  return !isNittetsu(lessorUser.email);
};

// 提案リスト(proposal-drafts)機能の表示制御に使うフラグ
export const isProposalDraftsEnabled = (
  lessorUser?: LessorUserForSplitFlags,
): boolean => {
  if (!lessorUser) return false;
  // MHCR様以外は利用できる
  // MHCR様はセキュリティ上ダウンロード機能をつけられないため、提案リスト機能も利用できないようにする
  return !isMHCR(lessorUser.email);
};

// 分析(dashboard)機能の表示制御に使うフラグ
export const isDashboardEnabled = (
  lessorUser?: LessorUserForSplitFlags,
): boolean => {
  if (!lessorUser) return false;
  // MHCR様以外は利用できる
  // MHCR様はセキュリティ上ダウンロード機能をつけられないため、分析機能も利用できないようにする
  return !isMHCR(lessorUser.email);
};
